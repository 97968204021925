<template>
    <div class="detail-wrap" v-loading="loading">
        <div class="content" v-html="shopInfo.shop_content"></div>
    </div>
</template>

<script>
import { shopInfo } from "@/api/shop/index";
import { adList } from "@/api/website";
export default {
  name: "shop_about",
  components: {},
  data: () => {
    return {
      id: 0,
      shopInfo: {}, //店铺信息
      searchContent: "",
      total: 0,
      loading: true,
      keyword: "",
      adList: []
    };
  },
  created() {
    this.id = this.$route.query.site_id;
    this.getAdList();
    this.getShopInfo();
  },
  watch: {
    $route(curr) {
      this.id = curr.params.pathMatch;
      this.getAdList();
      this.getShopInfo();
    }
  },
  methods: {
    getAdList() {
      adList({ keyword: "NS_PC_SHOP_ABOUT" })
        .then(res => {
          this.adList = res.data.adv_list;
          for (let i = 0; i < this.adList.length; i++) {
            if (this.adList[i].adv_url)
              this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
          }
        })
        .catch(err => err);
    },
    //获取店铺信息
    getShopInfo() {
      shopInfo({ site_id: this.id })
        .then(res => {
          if (res.code == 0) {
            this.shopInfo = res.data;
            this.loading = false;
          }
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  padding: 20px 0;
  background-color: #ffffff;
}
</style>
